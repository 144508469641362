<template>
    <div class="input-component">
        <div class="d-flex justify-content-between align-items-baseline">
            <label class="input-component-label">{{label}}</label>
            <!--<label v-if="forgotPassword" class="forgot-password-label">
                <a href="#">Esqueceu a senha?</a>
            </label>-->
        </div>
        <v-text-field :placeholder="placeholder"
                      :value="value"
                      v-on:input="$emit('input', $event)"
                      validate-on-blur
                      outlined filled dense
                      height="47" 
                      :color="color ? color : 'primary'"
                      :rules="rules"
                      :type="checkType"
                      :error="error"
                      :append-icon="sensive ? (hidden ? 'mdi-eye' : 'mdi-eye-off') : ''"
                      @click:append="hidden = !hidden"></v-text-field>
    </div>
</template>

<script>
    export default {
        name: "Input",

        data() {
            return ({
                hidden: this.sensive,
                defaultValue: this.value,
            })
        },

        props: {
            label: String,
            placeholder: String,
            forgotPassword: Boolean,
            sensive: Boolean,
            rules: Array,
            color: String,
            value: String,
            error: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'text'
            },
        },

        computed: {
            checkType: function () {
                return (this.sensive && this.hidden) ? 'password' : 'text';
            },
        }
    };
</script>

<style>
    .input-component-label {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.4rem;
        letter-spacing: -0.02rem;
    }

    .forgot-password-label {
        font-size: 1rem;
        font-weight: 500;
        text-decoration: underline;
        color: #2F80ED;
    }

    .input-component .v-text-field--outlined {
        border-radius: 0.35rem;
    }

    .input-component .v-text-field--outlined fieldset {
        background: #F8F8F8;
        border: 0.063rem solid #BBB;
    }

    .v-input__append-inner {
        margin-top: 12px !important;
    }
</style>