<template>
    <section class="container-form d-flex flex-column flex-md-row justify-content-between align-items-start w-100">
        <div class="text-form mt-md-16 mt-5 align-self-start">
            <h1 class="mb-5">Bem-vindo ao Tobias Informador</h1>
            <p v-html="text" class="paragraph-form"></p>
            <v-alert border="left"
                     dense
                     color="#0b62a4"
                     text>
                <div class="d-flex align-items-center">
                    <v-img :src="sgp" class="mr-3" max-height="30px" max-width="30px" contain></v-img>
                    O Tobias Informador é integrado com o SGP.<br />Realize seu cadastro utilizando as suas credenciais do sistema.
                </div>
            </v-alert>
        </div>

        <div class="divider divider-cadastro" />

        <div class="d-flex flex-column justify-content-between py-16">
            <v-form ref="form"
                    class="standard-form"
                    v-model="valid"
                    @submit.prevent="submitForm">

                <InputComponent label="Nome completo"
                                :rules="[rules.requiredRule, rules.nameRule]"
                                v-model="formModel.Name"
                                :error="errorLogin" />

                <InputComponent label="E-mail do SGP"
                                :rules="[rules.requiredRule, rules.userSGPRule]"
                                type="email"
                                v-model="formModel.Email"
                                :error="errorLogin" />

                <InputComponent label="Senha do SGP"
                                :rules="[rules.requiredRule, rules.passwordRule]"
                                v-model="formModel.Password"
                                :error="errorLogin"
                                sensive />

                <InputComponent label="Confirmar senha do SGP"
                                :rules="[rules.requiredRule, checkPswd]"
                                v-model="formModel.ConfirmPassword"
                                :error="errorLogin"
                                sensive />

                <v-alert border="left" dense
                         type="error" text
                         class="mt-0"
                         v-if="showMessageError">
                    {{errorMessage}}
                    <!--As credenciais inseridas <strong>não</strong> estão<br />cadastradas no SGP.-->
                </v-alert>

                <v-btn :style="styleButton"
                       elevation="0"
                       class="btn w-100 mt-3"
                       :loading="loading"
                       type="submit">{{getTextButtonRegister}}</v-btn>
            </v-form>
        </div>
    </section>
</template>

<script>
    import rules from '../utils/rules';
    import sgp from '../assets/icon-sgp.png';

    import InputComponent from '../components/Input.vue';

    export default {
        name: 'Cadastro',

        components: {
            InputComponent
        },

        data() {
            return ({
                formModel: {
                    Name: '',
                    Email: '',
                    Password: '',
                    ConfirmPassword: '',
                },
                errorLogin: false,
                showMessageError: false,
                sgp,
                rules,
                loading: false,
                valid: false,
                text:
                    `
                      O cadastro no Tobias Informador é <strong>integrado com o SGP</strong>. Preencha o formulário com seus dados de
                      cadastro do SGP para finalizar a criação de seu perfil.<br/><br/><strong>Esses dados servirão para que,
                      mais tarde possamos te enviar os informes de sua preferência.</strong>
                    `,
                styleButton: {
                    backgroundColor: this.$colors.primary,
                    color: this.$colors.grays.white,
                },
                errorMessage: "Usuário e/ou senha inválidos",
            })
        },

        methods: {
            submitForm: function () {
                if (this.errorMessage !== "Usuário já cadastrado") {

                    console.log('foi')
                    if (this.$refs.form.validate()) {
                        this.handleRegister()
                    }
                } else {
                    this.$router.push({ name: 'Login', params: { formModel: this.formModel } })
                }
            },

            handleRegister: function () {
                this.loading = true;

                let Name = this.formModel.Name
                let Email = this.formModel.Email;
                let Password = this.formModel.Password;
                this.$store.dispatch('register', { Name, Email, Password })
                    .then((res) => {
                        if (res.data.success) {
                            this.errorLogin = false;
                            this.showMessageError = false;
                            setTimeout(() => {
                                this.$router.push({ name: 'Selecionar' }) }, 500)
                            this.loading = false;
                        } else {
                            this.errorMessage = res.data.message;
                            this.errorLogin = true;
                            this.showMessageError = true;
                            this.loading = false;
                        }
                    })
                    .catch(() => {
                        this.errorLogin = true;
                        this.showMessageError = true;
                        this.loading = false;
                    })
            },
        },

        computed: {
            checkPswd: function () {
                return this.formModel.pswd === this.formModel.confirmPswd || "Senhas não conferem"
            },

            getTextButtonRegister: function () {
                return this.errorMessage === "Usuário já cadastrado" ? "Fazer login" : "Cadastrar"
            },
        }
    }
</script>

<style>
    .divider-cadastro {
        background: linear-gradient(
            180deg,
            rgba(67, 188, 108, 0) 0%,
            #43BC6C 36.98%,
            #43BC6C 66.67%,
            rgba(67, 188, 108, 0) 100%
        );
    }

    @media (max-width: 960px) {
        .divider-cadastro {
            background: linear-gradient(
                90deg,
                rgba(67, 188, 108, 0) 0%,
                #43BC6C 36.98%,
                #43BC6C 66.67%,
                rgba(67, 188, 108, 0) 100%
            );
        }
    }
</style>