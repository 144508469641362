const rules = {
    requiredRule: value => !!value || 'Campo obrigatório',

    nameRule: value => {
        if (/[0-9]/.test(value)) return 'Nome inválido'
        else if (!value.trim().includes(' ')) return "Insira o nome completo"
        else return true;
    },

    userSGPRule: () => {
        return true;
    },

    passwordRule: value => {
        return value.length >= 8 || 'A senha deve possuir pelo menos 8 caracteres'
    }
};

export default rules;